//MNE
import { en } from "vuetify/lib/locale";

export const locale = {
  current: "me",
  common: {
    all: "Sve",
    yes: "Da",
    no: "Ne",
    close: "Zatvori",
    confirm: "Potvrdi",
    save: "Sačuvaj",
    created: "Krerano",
    create: "Kreiraj",
    add: "Dodaj",
    validTo: "Važi do",
    edit: "Uredi",
    delete: "Izbriši",
    submit: "Potvrdi",
    cancel: "Otkaži",
    send: "Pošalji",
    createdOn: "Kreirano:",
    description: "Opis",
    price: "Cijena",
    error: "Greška",
    active: "Aktivno",
    inStock: "Na lageru",
    restrictions: "Ograničenja",
    name: "Naziv",
    Name: "Ime",
    quantity: "Količina",
    oldPrice: "Stara cijena",
    discount: "Popust (%)",
    priceDiscount: "Cijena sa popustom",
    shopId: "ID prodavnice",
    title: "Naziv",
    text: "Tekst",
    logo: "Logo",
    activated: "Aktivirano",
    deactivated: "Dektivirano",
    enabled: "Omogućeno",
    disabled: "Onemogućeno",
    email: "E-mail",
    user: "Korisnik",
    link: "Veza",
    thumbnail: "Sličica",
    type: "Tip",
    shop: "Prodavnica",
    shops: "Prodavnice",
    image: "Slika",
    start: "Početak",
    end: "Kraj",
    max: "Max",
    min: "Min",
    activeEnabled: "Aktivno: Omogućeno",
    activeDisabled: "Aktivno: Onemogućeno",
    suspended: "Suspendovan",
    notSuspended: "Nije suspendovan",
    total: "Ukupno",
    savedSuccessfully: "Sačuvano uspješno",
    itemCount: "Broj objava",
    items: "Artikli",
    members: "Članovi",
    copy: "Kopiraj",
    programId: "Program ID",
    programName: "Program Name",
    validFrom: "Validno od",
    validUntil: "Validno do",
    createdAt: "Datum kreiranja",
    optionalFilters: "Opcioni filteri",
    content: "Sadržaj",
    areYouSure: "Da li ste sigurni",
    selectMultipleImages: "Izaberite više slika",
    addTagOnEnter: "Dodajte tag na enter",
    availability: "Dostupnost",
    color: "Boja",
    size: "Velicina",
    restrictionType: "Tip Restrikcije",
    people: "Osoba",
    date: "Datum",
    startTime: "Vrijeme početka",
    endTime: "Vrijeme završetka",
    newCampaign: "Nova kampanja",
    selectAll: "Izaberi sve",
    view: "Pregledaj",
    newLimitation: "Nova limitacija",
    copyLimitation: "Kopiraj limitaciju",
    editLimitation: "Uredi limitaciju",
    editCampaign: "Uredi kampanju",
    copyCampaign: "Kopiraj kampanju",
    timePeriod: "Vremenski period",
    listOfShops: "Lista prodavnica",
    listOfVouchers: "Lista vaučera",
    sumPerShop: "Suma po prodavnicama",
    addShopToList: "Dodaj prodavnicu u listu",
    nameOfLimit: "Naziv ograničenja",
    nameOfShop: "Naziv prodavnice",
    limitPerShop: "Ograničenje po prodavnici",
    limitForCampaign: "Ograničenje po kampanji",
    shopsChosen: "Izabrane prodavnice",
    voucherVariants: "Varijante vaučera",
    inAction: "U akciji",
    reachedTheLimit: "Dostignuto ograničenje",
    activeLimitsSum: "Suma aktivnih ograničenja",
    leftLimitsSum: "Suma preostalih ograničenja",
    spentLastMonth: "Potrošeno prošlog meseca",
    spentLastYear: "Potrošeno prošle godine",
    limitSet: "Ograničenje postavljeno",
    spent: "Potrošeno",
    left: "Preostalo",
    archive: "Arhiva",
    campaigns: "Kampanje",
    sum: "Iznos",
    addNewVariant: "Add new variant",
    startDateFuture: "Datum početka ne može biti u budućnosti",
    atLeastOneItem: "Mora sadržati barem jedan proizvod",
    city: "Grad"
  },
  month: {
    jan: "Januar",
    feb: "Februa",
    mar: "Mart",
    apr: "April",
    may: "Maj",
    jun: "Jun",
    jul: "Jul",
    aug: "Avgust",
    sep: "Septembar",
    oct: "Oktobar",
    nov: "Novembar",
    dec: "Decembar"
  },
  $vuetify: {
    ...en,
    dataTable: {
      itemsPerPageText: "Redova po strani:",
      ariaLabel: {
        sortDescending: "Sortirano opadajuća.",
        sortAscending: "Sortirano rastuće.",
        sortNone: "Nije sortirano.",
        activateNone: "Aktivirajte za uklanjanje sortiranja.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending."
      },
      sortBy: "Sort by"
    },
    dataFooter: {
      itemsPerPageText: "Stavki po strani:",
      itemsPerPageAll: "Sve",
      nextPage: "Sljedeća strana",
      prevPage: "Prethodna strana",
      firstPage: "Prva strana",
      lastPage: "Posljednja strana",
      pageText: "{0}-{1} od {2}"
    }
  },
  menu: {
    dashboard: "Kontrolna tabla",
    users: "Korisnici",
    shops: "Prodavnice",
    shopPosts: "Objave prodavnica",
    socialPosts: "Društvene objave",
    banners: "Baneri",
    messages: "Poruke",
    broadcast: "Broadcast",
    shopAdvertisedPosts: "Reklamirani postovi prodavnice",
    orders: "Narudžbe",
    shoppingEvents: "Shopping Events",
    donationGoals: "Donation Goals",
    categories: "Kategorije",
    shopCategories: "Kategorije prodavnice",
    oobleeCategories: "Ooblee kategorije",
    recommendedCategories: "Preporuceno",
    mainFeedCategories: "Main Feed kategorije",
    mainPageSectors: "Sektori glavne strane",
    sponsoredBy: "Sponzorisano od",
    eventsAndNews: "Događaji i novosti",
    suggestedForYou: "Preporučeno za vas",
    recommendedBy: "Preporučeno od",
    promotions: "Promocije",
    titleDefinitions: "Definicije naziva",
    promotionInstances: "Instance promocije",
    bannerDefinitions: "Definicije banera",
    bannerInstances: "Instance banera",
    broadcastMessages: "Emitovane poruke",
    memberships: "Članarine",
    apUsers: "AP korisnici",
    scheduler: "Planer",
    influencers: "Influenseri",
    allUsers: "Svi korisnici",
    referralInvitation: "Referrals",
    coins: "Coins",
    campaigns: "Kampanje",
    welcomeScreen: "Ekran Dobrodošlice",
    advertising: "Reklamiranje",
    vouchers: "Vaučer",
    voucherList: "Lista vaučera",
    voucherLimitations: "Limitacija vaučera",
    wheel: "Točak sreće"
  },
  commonTable: {
    email: "Email",
    date: "Datum",
    userName: "Korisničko ime",
    user: "Korisnik",
    category: "Kategorija",
    categories: "Kategorije",
    shoppingEventPosts: "Objave događaja kupovine",
    total: "Ukupno",
    published: "Objavljeno",
    city: "Grad",
    country: "Država",
    cities: "Gradovi",
    shops: "Prodavnice",
    shopName: "Naziv prodavnice",
    shopId: "ID prodavnice",
    productName: "Naziv proizvoda",
    productPicture: "Slika proizvoda",
    productDescription: "Opis proizvoda",
    productPrice: "Cena proizvoda",
    productDiscount: "Popust na proizvod",
    discountInPercentage: "Popust u %",
    discountInCurrency: "Popust u €",
    oobleeDiscountInCurrency: "Ooblee popust u €",
    status: "Status",
    product: "Proizvod",
    price: "Cena",
    orderType: "Tip narudžbine",
    paid: "Plaćeno",
    finalPrice: "Konačna cena",
    priceBeforeDiscount: "Cena pre popusta",
    oldPrice: "Stara cena",
    adminStatus: "Status (Admin)",
    shopStatus: "Status (Prodavnica)",
    quantity: "Količina",
    shoppingEvent: "Dogadjaj kupovine",
    shopProduct: "Proizvod prodavnice",
    donationAmount: "Iznos donacije",
    promoStart: "Početak promocije",
    promoFinish: "Kraj promocije",
    userRegStart: "Registracija korisnika počinje od",
    userRegFinish: "Registracija korisnika završava se na",
    numOfRef: "Broj novih preporučenih korisnika",
    voucherValue: "Vrednost vaučera",
    name: "Ime",
    startFrom: "Počevši od",
    finished: "Završeno",
    validFrom: "Validno Od",
    validUntil: "Validno Do",
    type: "Tip"
  },
  pages: {
    shops: {
      entityName: "naziv",
      email: "email",
      phoneNumber: "broj telefona",
      date: "datum",
      cityCountry: "grad (država)",
      activated: "aktiviran",
      groupShopping: "grupna trgovina",
      suggestedShopping: "preporučena trgovina",
      redirectUrl: "URL za redirekciju",
      donations: "donacije",
      personalSpecialOffer: "lična posebna ponuda",
      banned: "zabranjen",
      cityShopping: "gradska trgovina",
      subscriptions: "pretplate",
      gift: "poklon",
      promotions: "promocije",
      shop: "prodavnica",
      tags: "tagovi",
      edit: "uredi",
      me: "Crnogorski",
      language: "jezik",
      shops: "Prodavnice",
      activeShopsWithPostCount: "Aktivne prodavnice sa brojem postova"
    },
    shopPosts: {
      shopName: "Naziv prodavnice",
      date: "Datum",
      productPicture: "Slika Proizvoda",
      productName: "naziv Proizvoda",
      gender: "Pol",
      productDescription: "Opis Proizvoda",
      productDiscount: "Popust",
      productPrice: "Cijena proizvoda",
      city: "Grad",
      category: "Kategorija",
      published: "Objavljeno",
      editors: "Uredjivači",
      product: "Proizvod",
      categoryPicker: "Izbor Kategorija",
      tags: "Tagovi",
      delete: "Izbriši",
      edit: "Uredi",
      catPickerFor: "Izbor Kategorije Za",
      shopPostTags: "Tagovi objave prodavnice",
      areYouSureDelete: "Da li ste sigurni da želite da obrišete objavu ",
      fromEvents: " iz događaja?",
      detailsForShopPost: "Detalji objave "
    },
    socialPosts: {
      userProfileId: "ID profila korisnika",
      postPicture: "Slika objave",
      postDescription: "Opis objave",
      published: "Objavljeno",
      post: "Objava",
      postCreator: "Kreator posta",
      content: "Sadržaj",
      likeCount: "Broj svidjanja",
      commentCount: "Broj komentara",
      sharedPostType: "Tip dijeljenog posta"
    },
    banners: {
      banner: "Baner",
      bannerId: "ID banera",
      bannerPicture: "Slika banera",
      bannerText: "Tekst banera"
    },
    advertisedPosts: {
      apr: "Izveštaj o oglašavanim objavama",
      tr: "Ukupni izvještaj"
    },
    orders: {
      orderId: "ID Porudžbine",
      customerName: "Ime Kupca",
      customerEmail: "Email Kupca",
      colorSize: "Boja/Veličina",
      orderOption: "Opcija poručivanja",
      location: "Lokacija",
      totalPrice: "Ukupna Cijena",
      disputeMessage: "Poruka osporavanja",
      orderInfo: "Informacije o porudžbini",
      or: "Izvještaj o porudžbinama",
      oir: "Izvještaj o poručenim proizvodima",
      locationReservation: "Lokacija rezervacije",
      customerAddress: "Adresa kupca",
      customerPhone: "Telefon Kupca",
      customerInfo: "Informacije o kupcu"
    },
    recommendedCategories: {
      insertCategory: "Dodaj kategoriju",
      allCities: "Svi Gradovi",
      deleteCatMessage: "Jeste li sigurni da želite da izbrišete kategoriju sa id =",
      allEnabled: "Svi gradovi: Omogućeno",
      allDisabled: "Svi gradovi: Onemogućeno"
    },
    shopCategories: {
      order: "Redoslijed",
      name: "Naziv",
      categoryType: "Tip kategorije",
      advertRelevant: "Relevantno za reklame",
      createPostRelevant: "Relevantno za kreiranje objave",
      iconNormalPNG: "Ikonica normalna (PNG)",
      iconActivePNG: "Ikonica aktivna (PNG)",
      iconNormalSVG: "Ikonica normalna (SVG)",
      iconActiveSVG: "Ikonica aktivna (SVG)",
      deleteMessage: "Jeste li sigurni da želite da izbrišete kategoriju ",
      detailsTitle: "Detalji kategorije prodavnice",
      insertTitle: "Dodaj kategoriju prodavnice"
    },
    mfCategories: {
      order: "Redoslijed",
      name: "Naziv kategorije",
      image: "Slika",
      city: "Grad",
      active: "Aktivno"
    },
    sectors: {
      sectorName: "Naziv Sektora",
      shops: "Prodavnice",
      type: "Tip",
      active: "Aktivan",
      deleteSectorMessage: "Jeste li sigurni da želite da izbrišete sektor sa id =",
      donationBanner: "Baner donacije"
    },
    sponsoredBy: {
      deleteMessage: "Jeste li sigurni da želite da izbrišete sponzora sa id =",
      sponsoredBy: "Sponzorisano od",
      sponsor: "Sponzor"
    },
    suggestedForYou: {
      editSuggested: "Predloženi detalji",
      insertSuggested: "Dodaj novo predloženo za tebe",
      deleteMessage: "Jeste li sigurni da želite da izbrišete predloženo za vas sa id ="
    },
    news: {
      deleteMessage: "Jeste li sigurni da želite da izbrišete novosti sa id ="
    },
    recommendedBy: {
      deleteMessage: "Jeste li sigurni da želite da izbrišete preporučeno sa id ="
    },
    titleDefinition: {
      deleteMessage: "Jeste li sigurni da želite da izbrišete definiciju naslova sa id ="
    },
    promotions: {
      deleteMessage: "Jeste li sigurni da želite da izbrišete promociju sa id =",
      addNew: "Dodaj novu prodavnicu"
    },
    bannerDefinitions: {
      linkType: "Vrsta veze",
      bannerType: "Vrsta banera",
      deleteMessage: "Jeste li sigurni da želite da izbrišete definiciju banera sa id = ",
      instanceDelete: "Jeste li sigurni da želite da izbrišete instancu banera sa id = "
    },
    broadcastMessages: {
      subject: "Predmet",
      body: "Sadržaj",
      schedule: "Raspored",
      sent: "Poslato",
      deleteMessage: "Jeste li sigurni da želite da izbrišete emitovanu poruku sa id ="
    },
    membership: {
      shopId: "ID Prodavnice",
      shopName: "Naziv Prodavnice",
      commission: "Provizija",
      validTo: "Važi do",
      subscriptionId: "ID pretplate",
      subscriptionName: "Naziv pretplate",
      subscriptionType: "Tip pretplate",
      autoRenewal: "Automatska obnova",
      lastPaymentDate: "Datum zadnje uplate",
      invoiceUrl: "URL Računa",
      invoiceStatus: "Status Računa"
    },
    apUsers: {
      activationDate: "Datum aktivacije",
      superAdmin: "Super Admin",
      cityRoles: "City Role",
      fullName: "Puno Ime",
      password: "Lozinka",
      repeatPassword: "Ponovi Lozinku"
    },
    users: {
      influencer: "Influenser",
      gender: "Pol",
      activationDate: "Datum Aktivacije",
      profile: "Profil",
      suspended: "Suspendovan"
    },
    influencers: {
      influencer: "Influenser",
      gender: "Pol",
      requestedDate: "Datum zahtjeva",
      profile: "Profil",
      suspended: "Suspendovan",
      approveInfluencer: "Odobri Influensera",
      sendEmail: "Pošalji email"
    },
    scheduler: {
      startDateAndTime: "Početni datum i vrijeme",
      endDateAndTime: "Završni datum i vrijeme",
      postCount: "Broj postova",
      plannedCost: "Planirana potrošnja",
      publishOnScheduler: "Objavi na planeru"
    }
  },

  lang: {
    me: "Crnogorski",
    en: "Engleski",
    de: "Njemački"
  },
  gender: {
    male: "Muški",
    female: "Ženski",
    unisex: "Unisex",
    all: "Sve"
  },
  influencerStatuses: {
    requestSent: "Poslat zahtjev",
    emailSent: "Poslat email",
    active: "Aktivno",
    underReview: "U razmatranju",
    rejected: "Odbijeno"
  },
  date: {
    from: "Od",
    to: "Do"
  },
  shopUpdate: {
    taxId: "Poreski broj",
    bankAccount: "Broj žiro računa",
    submit: "Potvrdi",
    cancel: "Otkaži",
    clearance: "Nivo odobrenja",
    details: "Detalji za prodavnicu ",
    lvl1: "Nivo odobrenja 1",
    lvl2: "Nivo odobrenja 2",
    lvl3: "Nivo odobrenja 3",
    zipCode: "Zip Kod"
  },
  voucher: {
    addUser: "Dodaj korisnika na kupon",
    addShop: "Dodaj prodavnicu na kupon",
    campaignWarning:
      "Kampanje ne mogu imati ponavljajuće prodavnice u istom vremenskom periodu. Molimo odaberite drugi vremenski period za ponavljajuće prodavnice."
  },
  swGift: {
    gifts: "Pokloni",
    gValue: "Vijednost poklona",
    gValid: "Važi do",
    addNew: "Dodaj novi poklon",
    value: "Vrijednost",
    used: "Korišteno",
    giftsForShop: "Pokloni prodavnice "
  },
  scs: {
    add: "Dodaj novi Shopping grad",
    city: "Grad"
  },
  promoSub: {
    promoSub: "Pretplate za promocije",
    ood: "Ponuda dana",
    so: "Specijalna ponuda",
    se: "Shopping događaj",
    subFor: "Pretplate za prodavnicu "
  },
  welcomeScreen: {
    addNewSettingsFor: "Dodajte nove postavke grada za ",
    addNewSettings: "Dodajte nove postavke",
    editSettings: "Uredi postavke",
    addNewModification: "Dodajte novu modifikaciju",
    viewInfo: "Pregledaj dodatne informacije podešavanja",
    fieldPlacement: "Primjer pozicije polja",
    additionalInfo: "Dodatne informacije podešavanja",
    BOTTOM_PARAGRAPH: "Donji odlomak",
    WELCOME_PARAGRAPH: "Dobrodošli odlomak",
    TITLE: "Naslov",
    LEGAL_TEXT: "Pravni tekst",
    LEGAL_TITLE: "Pravni naslov",
    HEADER_IMAGE: "Slika zaglavlja",
    FOOTER_IMAGE: "Slika podnožja",
    DEFAULT_CITY_SETTINGS: "Podrazumevane postavke grada",
    CITY_SETTINGS: "Postavke grada",
    DEFAULT_SETTINGS: "Podrazumevane postavke",
    timeInfo:
      "Vreme koje je prikazano će biti konvertovano u UTC vreme. Poruka može biti poslata u roku od 1-2 sata od zakazanog vremena.",
    campaignInfo:
      "Između završetka prethodne kampanje i početka sledeće, fiksirane vrednosti za navedena polja će biti prikazane.",
    TOP_PARAGRAPH: "Gornji odlomak",
    MAIN_TITLE: "Glavni naslov",
    BUTTON_TEXT: "Tekst dugmeta",
    BELOW_BUTTON_TEXT: "Tekst ispod dugmeta"
  },
  coins: {
    enums: {
      Affiliate: "Affiliate",
      LoyaltyClubMember: "Član Loyalty Kluba",
      Regular: "Regular",
      RegularShopper: "Regular Shopper",
      VipClubMember: "Član VIP Kluba"
    },
    coins: "coina",
    generalRules: "Opšta pravila",
    voucherExchangeRule: "Pravilo zamene vaučera",
    defaultForAllPlaces: "Podrazumevano za sve lokacije",
    generalSettings: "Opšta podešavanja",
    coinWillHaveValue: "Coin će imati vrednost",
    exchangeConditions: "Uslovi zamene",
    whenUserHas: "Kada korisnik ima",
    recieveVoucher: "korisnik će automatski primiti vaučer",
    explanationPopup: "Objašnjenje iskačućeg prozora",
    campaignAndSpecialOffers: "Kampanje i posebne ponude",
    newCampaign: "Nova kampanja",
    campaignName: "Naziv kampanje",
    promoStart: "Početak promocije",
    promoFinish: "Završetak promocije",
    archive: "Arhiva"
  }
};
